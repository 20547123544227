import { Injectable } from '@angular/core';
import { QueryTokenDTO } from '@paragondata/ngx-ui/filter';
import {
  DateRange,
  QueryTokenDto,
} from '@swagger/generated/humanresources/models';
import { ContributorScheduleService } from '@swagger/generated/humanresources/services';
import { catchError, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HrpContributorScheduleService {
  /**
   * List schedules of employee / Zeitpläne/Kalender des Mitarbeiters.
   */
  employeeScheduleListSchedules(employeeUId: string) {
    return this.employeeSchedule.contributorScheduleListSchedules({
      contributorUId: employeeUId,
    });
  }

  /**
   * List available schedule item types / Verfügbare Zeit-/Kalendereintragsarten.
   */
  employeeScheduleListAvailableScheduleItemTypes(
    employeeUId: string,
    scheduleUId: string,
    start: Date,
    stop: Date
  ) {
    const body = [{ start: start?.toISOString(), stop: stop?.toISOString() }];
    return this.employeeSchedule.contributorScheduleListAvailableScheduleItemTypes(
      { contributorUId: employeeUId, scheduleUId, body }
    );
  }

  /**
   * Query schedules / Zeitpläne abfragen.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employeeScheduleQueryEmployeeSchedules$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeScheduleQueryEmployeeSchedules(
    start: Date,
    stop: Date,
    groupFilter?: QueryTokenDTO,
    skip?: number,
    take?: number
  ) {
    let timespan: string = `"${start.toISOString()}"-"${stop.toISOString()}"`;

    const queryToken: QueryTokenDto = {
      filter: {
        timespan: timespan,
        ...groupFilter.filter,
      },
      input: groupFilter.input, // Assuming input property is present in groupFilter
      orderBy: groupFilter.orderBy, // Assuming orderBy property is present in groupFilter
      take: take,
      skip: skip,
    };

    return this.employeeSchedule.contributorScheduleQueryContributorSchedules({
      body: queryToken,
    });
  }

  employeeScheduleQuerySettings() {
    return this.employeeSchedule.contributorScheduleQueryContributorSchedulesSettings();
  }

  employeeScheduleWorkingTimeDistribution({
    employeeUId,
    scheduleUId,
    range,
  }: {
    employeeUId: string;
    scheduleUId: string;
    range: DateRange;
  }) {
    return this.employeeSchedule
      .contributorScheduleWorkingTimeDistribution({
        contributorUId: employeeUId,
        scheduleUId,
        body: range,
      })
      .pipe(
        map((response) => response.result),
        catchError((error) => {
          throw error;
        })
      );
  }

  constructor(private employeeSchedule: ContributorScheduleService) {}
}
